<div *ngIf="loaded" fxLayout="row wrap" fxLayoutAlign="center center" class="layout-wrapper">

  <div fxFlex="95%" fxFlex.lt-md="100%" class="flex-wrapper">

    <mat-sidenav-container fixedTopGap="56" (backdropClick)="sidenav.close()">

      <mat-sidenav #sidenav role="navigation">
        test
      </mat-sidenav>
      <mat-toolbar >Menu</mat-toolbar>
      <mat-sidenav-content>
        <!--in here all the content must reside. We will add a navigation header as well-->
        <app-topnav (sidenavToggle)="sidenav.toggle()"></app-topnav>
        <main>
          <router-outlet></router-outlet>
        </main>
        <app-bottom-nav></app-bottom-nav>

      </mat-sidenav-content>

    </mat-sidenav-container>

  </div>

</div>
