import { Component, OnInit } from '@angular/core';

import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html'
})
export class BottomNavComponent implements OnInit {

  version;
  testMode = true;

  constructor(private appConfigService: AppConfigService) { }

  ngOnInit(): void {

    if (this.appConfigService.getAppMODE() === 'Prod') {
      this.testMode = false;
    }
    this.version = '(' + this .appConfigService.getAppCLIENT() + '-';
    this.version += this .appConfigService.getAppMODE() + '-';
    this.version += this.appConfigService.getAppVERSION() + ')';
    this.version += '    ' + this.appConfigService.getAppDATE();

  }

}
