import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from '../app/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {

  title = 'StockIT';

  appConfig;
  loaded: boolean;

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {

  }



  ngOnInit() {

    this.http.get('../assets/config/stockitConfig.json')
      .toPromise()
      .then(data => {


        this.appConfig = data;

        this.appConfigService.setAppURL(this.appConfig.url);
        this.appConfigService.setAppCLIENT(this.appConfig.client);
        this.appConfigService.setAppMODE(this.appConfig.mode);
        this.appConfigService.setAppVERSION(this.appConfig.version);
        this.appConfigService.setAppDATE(this.appConfig.deploydate);

        this.loaded = true;

        console.log('---------------- StockIT StartUP ---------------------');
        console.log('Server:      ' + this.appConfigService.getAppURL());
        console.log('Version:     ' + this.appConfigService.getAppVERSION());
        console.log('Mode:        ' + this.appConfigService.getAppMODE());
        console.log('-------------------------------------------------------');

      });
  }

}

