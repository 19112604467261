import { Injectable } from '@angular/core';

// cdk
import { Overlay} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';

// rxjs
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class SpinnerService {

    private spinnerTopRef = this.cdkSpinnerCreate();

    spin$: Subject<boolean> = new Subject();

    constructor(
        private overlay: Overlay,
    ) {



    }

    private cdkSpinnerCreate() {
        return this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        })
    }

    public startSpinner(){
      this.spinnerTopRef.detach();
      this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
    }

    public stopSpinner(){
      this.spinnerTopRef.detach() ;
    }
}
