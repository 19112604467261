import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { AppConfigService } from '../../app-config.service';
import { AuthenticationService } from '../../core/service/authentication.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})

export class TopnavComponent implements OnInit {

  testMode = true;

  constructor(private authenticationService: AuthenticationService,
              private appConfigService: AppConfigService
    ) { }

  ngOnInit() {
    if (this.appConfigService.getAppMODE() === 'Prod') {
      this.testMode = false;
    }
  }

  public logout(){
    this.authenticationService.logOut();
  }

  public isUserLoggedIn() {
    return this.authenticationService.isUserLoggedIn();
  }

  public getLogedInUser() {
    return sessionStorage.getItem('email');
  }



}
