

<button *ngIf="isUserLoggedIn()" mat-button [matMenuTriggerFor]="inventory">Inventory</button>
<button *ngIf="isUserLoggedIn()" mat-button [matMenuTriggerFor]="receipt">Receipts</button>
<button *ngIf="isUserLoggedIn()" mat-button [matMenuTriggerFor]="order">Orders</button>
<button *ngIf="isUserLoggedIn()" mat-button [matMenuTriggerFor]="client">Clients</button>

<button *ngIf="isUserLoggedIn() && isUserAdmin()" mat-button [matMenuTriggerFor]="admin">Admin</button>

<button *ngIf="!isUserLoggedIn()" mat-button [routerLink]="'/login'" >Login</button>

<button *ngIf="isUserLoggedIn()" mat-button (click)="logout()">Logout ({{getLogedInUser()}})</button>


<mat-menu #inventory="matMenu">
  <button mat-menu-item [routerLink]="'/warehouse/warehouses'">Warehouse</button>
  <button mat-menu-item [routerLink]="'/location/locations'">Locations</button>
  <button mat-menu-item [routerLink]="'/part/parts'">Parts</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/inventory/inventorys'">Inventory</button>
  <button mat-menu-item [routerLink]="'/transaction/transactions'">Transaction History</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/adjustment/transact'">Adjustment</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/move/single/transact'">Move</button>
  <button mat-menu-item [routerLink]="'/move/bulk/transact'">Bulk Move</button>
</mat-menu>

<mat-menu #receipt="matMenu">
  <button mat-menu-item [routerLink]="'/receipt/headers'">Receipts</button>
  <button mat-menu-item [routerLink]="'/putaway/transact'">Put-A-Way</button>
</mat-menu>

<mat-menu #order="matMenu">
  <button mat-menu-item [routerLink]="'/order/headers'">Orders</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/picklist/headers'">Picklists</button>
  <!-- <button mat-menu-item [routerLink]="'/picklist/truckMountPick'">Truck Mount Picking</button> -->
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/shiplist/headers'">Shiplists</button>
  <button mat-menu-item [routerLink]="'/shiplistdetail/report'">Shipping Report</button>
</mat-menu>

<mat-menu #client="matMenu">
  <button mat-menu-item [routerLink]="'/client/clients'">Clients</button>
  <button mat-menu-item [routerLink]="'/billing/billings'">Billing</button>
</mat-menu>

<mat-menu #admin="matMenu">
  <button mat-menu-item [routerLink]="'/systemdata/system'">System</button>
  <button mat-menu-item [routerLink]="'/user/users'">Users</button>
  <mat-divider></mat-divider>
  <button  mat-menu-item (click)="clearCache()">Clear Chache</button>
  <button mat-menu-item [routerLink]="'/import/file'">Import</button>
  <button *ngIf="testMode" mat-menu-item (click)="runBilling()">Run Billing</button>
  <button *ngIf="testMode" mat-menu-item (click)="runInventory()">Run Inventory Check</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/log/jobs'">Job Logs</button>
  <button mat-menu-item [routerLink]="'/log/errors'">Error Logs</button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="'/barcode/test'">Test WS</button>
</mat-menu>



