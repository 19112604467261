import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: '',  loadChildren: () => import('../adjustment/adjustment.module').then(m => m.AdjustmentModule) },
  { path: '',  loadChildren: () => import('../transaction/transaction.module').then(m => m.TransactionModule) },
  { path: '',  loadChildren: () => import('../inventory/inventory.module').then(m => m.InvnetoryModule) },
  { path: '',  loadChildren: () => import('../warehouse/warehouse.module').then(m => m.WarehouseModule) },
  { path: '',  loadChildren: () => import('../location/location.module').then(m => m.LocationModule) },
  { path: '',  loadChildren: () => import('../part/part.module').then(m => m.PartModule) },
  { path: '',  loadChildren: () => import('../receipt/receipt.module').then(m => m.ReceiptModule) },
  { path: '',  loadChildren: () => import('../order/order.module').then(m => m.OrderModule) },
  { path: '',  loadChildren: () => import('../picklist/picklist.module').then(m => m.PicklistModule) },
  { path: '',  loadChildren: () => import('../shiplist/shiplist.module').then(m => m.ShiplistModule) },
  { path: '',  loadChildren: () => import('../client/client.module').then(m => m.ClientModule) },
  { path: '',  loadChildren: () => import('../billing/billing.module').then(m => m.BillingModule) },

  { path: '', redirectTo: '/home', pathMatch: 'full' }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class RoutingModule { }

