import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {


   constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {

        // return page errors
        if (err.status === 400) {
         return;
        }

        // page not found
        if (err.status === 404 ) {
          this.router.navigate(['404']);
          return;
         }

        // not authorised
        if (err.status === 401 ) {
          this.router.navigate(['login']);
          return;
         }

         // general error
        console.log('The Following Error Occured ' + err.message);
        this.router.navigate(['500']);
        return;
      }

    }));
  }
}
