<div class="footer" >

  <div class="row">

    <div class="column">
      <div *ngIf="testMode" style="color:lightcoral">Test system -- Do not configure Production data here.</div>
    </div>

    <div class="column">

    </div>

    <div class="column" style="text-align: right;">
          Version: {{version}}
    </div>

  </div>


</div>
