import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';

@Injectable({
  providedIn: 'root'
})

export class RestService {

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) { }

  public get = (route: string) => {
    return this.http.get(this.appConfigService.getAppURL() + '/' + route);
  }

  public getText = (route: string) => {
    return this.http.get(this.appConfigService.getAppURL() + '/' + route, {responseType: 'text'});
  }

  public getDocument = (route: string) => {
    return this.http.get(this.appConfigService.getAppURL() + '/' + route, { responseType: 'blob' } );
  }

  public getPaged = (route: string, parameters: string) => {
    return this.http.get(this.appConfigService.getAppURL() + '/' + route + '/' + parameters);
  }

  public getFiltered = (route: string, parameters: string) => {
    return this.http.get(this.appConfigService.getAppURL() + '/' + route + parameters);
  }

  public post = (route: string, body) => {
    return this.http.post(this.appConfigService.getAppURL() + '/' + route, body, this.generateHeaders());
  }

  public put = (route: string, body) => {
    return this.http.put(this.appConfigService.getAppURL() + '/' + route, body, this.generateHeaders());
  }

  public delete = (route: string) => {
    return this.http.delete(this.appConfigService.getAppURL() + '/' + route);
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

}
