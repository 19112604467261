import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable()
export class SpringHttpInterceptorService implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

      if (sessionStorage.getItem('username') && sessionStorage.getItem('basicauth')) {
        req = req.clone({
          setHeaders: {
            Authorization: sessionStorage.getItem('basicauth')
          }
        });
      }

      return next.handle(req);

    }
}
