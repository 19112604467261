import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule} from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { FlexLayoutModule} from '@angular/flex-layout';


import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AuthenticationService } from './core/service/authentication.service';
import { NotificationService } from './core/service/notification.service ';
import { RestService } from './core/service/rest.service';

import { GlobalHttpInterceptorService } from './core/service/global-http-interceptor.service';
import { SpringHttpInterceptorService } from './core/service/spring-http-interceptor.service';

import { SpinnerService } from './core/service/spinner.service';
import { ErrorDialogComponent } from './core/dialog/error-dialog/error-dialog.component';
import { ConfirmationDialogComponent } from './core/dialog/confirmation-dialog/confirmation-dialog.component';

import { LoginComponent } from './core/component/login/login.component';
import { NotFoundPageComponent } from './core/component/not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from './core/component/server-error-page/server-error-page.component';


import { HomeComponent } from './home/home.component';
import { RoutingModule } from './routing/routing.module';

import { TopnavComponent } from './navigation/topnav/topnav.component';
import { TopNavMenuComponent } from './navigation/top-nav-menu/top-nav-menu.component';
import { BottomNavComponent } from './navigation/bottom-nav/bottom-nav.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ErrorDialogComponent,
        ConfirmationDialogComponent,
        NotFoundPageComponent,
        ServerErrorPageComponent,
        HomeComponent,
        TopnavComponent,
        TopNavMenuComponent,
        BottomNavComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        FlexLayoutModule,
        RoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        NotificationService,
        AuthenticationService,
        RestService,
        SpinnerService,
        { provide: HTTP_INTERCEPTORS, useClass: SpringHttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
