<div mat-dialog-title>
  {{data.title}}
</div>

<div mat-dialog-content [innerHTML]="data.message">

</div>

<div mat-dialog-actions>
  <button mat-button class="dialogBtn" [mat-dialog-close]="true">{{data.okButtonText}}</button>
  <button mat-button class="dialogBtn" (click)="cancelClick()">{{data.cancelButtonText}}</button>
</div>

