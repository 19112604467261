<mat-toolbar color="primary">
  <div *ngIf="testMode" align="center" style="color:lightcoral">
    This is a test  - Do not configure production data here
  </div>
  <mat-toolbar-row>

    <button *ngIf="isUserLoggedIn()" mat-button [routerLink]="'/system/status'">
      <h1>StockIT</h1>
    </button>
    <button *ngIf="!isUserLoggedIn()" mat-button [routerLink]="'/home'">
      <h1>StockIT</h1>
    </button>

    <top-nav-menu></top-nav-menu>

  </mat-toolbar-row>

</mat-toolbar>
