<div mat-dialog-title>
    {{data.title}}
</div>

<div mat-dialog-content>
  {{data.message}}
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()">{{data.okButtonText}}</button>
</div>
