import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService  {

  constructor(private snackBar: MatSnackBar) {}

notifyUser(message: string) {
  this.snackBar.open(message, 'Close', {duration: 4000,
    panelClass: ['blue-snackbar']});
}

notifyError(message: string) {
  this.snackBar.open(message, 'Close', {duration: 4000,
    panelClass: ['red-snackbar']});
}

}


