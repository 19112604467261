import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { AuthenticationService } from '../../service/authentication.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  constructor(private router: Router,
              public dialog: MatDialog,
              private authenticationService: AuthenticationService) { }

  username: string;
  password: string;

  ngOnInit() {
  }

  login(): void {

    (this.authenticationService.authenticate(this.username, this.password).subscribe(
      data => {
        this.router.navigate(['system/status']);
      },
      error => {
        this.showError();
      }
    ))

  }

  showError() : void {
    this.dialog.open(ErrorDialogComponent, {
      data: {title: 'Login', message: 'Your credintals are not valid, plese try again!', okButtonText: 'Ok'} , width : '250px'
    });
  }

}

