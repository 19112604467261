import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ConfirmationDialogComponent } from '../../core/dialog/confirmation-dialog/confirmation-dialog.component';

import { AuthenticationService } from '../../core/service/authentication.service';
import { AppConfigService } from '../../app-config.service';
import { RestService } from '../../core/service/rest.service';
import { NotificationService } from '../../core/service/notification.service ';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'top-nav-menu',
  templateUrl: './top-nav-menu.component.html'
})



export class TopNavMenuComponent implements OnInit {

  testMode = true;

  constructor(
     private router: Router,
     private appConfigService: AppConfigService,
     private authenticationService: AuthenticationService,
     private restService: RestService,
     private notificationService: NotificationService,
     public dialog: MatDialog) { }

  ngOnInit() {
    if (this.appConfigService.getAppMODE() === 'Prod') {
      this.testMode = false;
    }
  }

  public logout() {
    this.authenticationService.logOut();
  }

  public getLogedInUser() {
    return sessionStorage.getItem('email');
  }

  public runBilling() {

    const dialogRef =  this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'Run Billing', message: 'Are you shure you want to run Billing. <br/><br/> Do Not Use On Live DATA',
      okButtonText: 'Yes', cancelButtonText: 'No'} ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        this.restService.getText('api/billings/run')
        .subscribe(res => {
          const message = res as string;
          this.notificationService.notifyUser(message);
          this.router.navigate(['billing/billings']);
        },
        (error => {
         this.notificationService.notifyUser(error.error[0].defaultMessage);
        }));

      }
    });


  }

  public runInventory() {

    const dialogRef =  this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'Run Inventory Reconciliation ', message: 'Are you shure you want to run the Inventory Reconciliation.' +
      '<br/><br/> Check the Logs for Errors after the run',
      okButtonText: 'Yes', cancelButtonText: 'No'} ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        this.restService.getText('api/inventorys/run')
        .subscribe(res => {
          const message = res as string;
          this.notificationService.notifyUser(message);
          this.router.navigate(['/log/errors']);
        },
        (error => {
         this.notificationService.notifyUser(error.error[0].defaultMessage);
        }));

      }
    });


  }


  public clearCache() {

    const dialogRef =  this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'Clear System Cache', message: 'Do you want to Clear the System Cache?', okButtonText: 'Yes', cancelButtonText: 'No'} ,
      width : '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.clear();
      }
    });



  }

  public isUserLoggedIn() {
    return this.authenticationService.isUserLoggedIn();
  }

  public isUserAdmin() {
    return this.authenticationService.isUserAdmin();
  }

}
