import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './core/component/login/login.component';
import { NotFoundPageComponent } from './core/component/not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from './core/component/server-error-page/server-error-page.component';

import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: '404', component: NotFoundPageComponent },
  { path: '500', component: ServerErrorPageComponent },

  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'log', loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule) },
  { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
  { path: 'systemdata', loadChildren: () => import('./system-data/system-data.module').then(m => m.SystemDataModule) },
  { path: 'adjustment', loadChildren: () => import('./adjustment/adjustment.module').then(m => m.AdjustmentModule) },
  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) },
  { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InvnetoryModule) },
  { path: 'warehouse', loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule) },
  { path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule) },
  { path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule) },
  { path: 'contact', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
  { path: 'part', loadChildren: () => import('./part/part.module').then(m => m.PartModule) },
  { path: 'putaway', loadChildren: () => import('./putaway/putaway.module').then(m => m.PutawayModule) },
  { path: 'receipt', loadChildren: () => import('./receipt/receipt.module').then(m => m.ReceiptModule) },
  { path: 'move', loadChildren: () => import('./move/move.module').then(m => m.MoveModule) },
  { path: 'order', loadChildren: () => import('./order/order.module').then(m => m.OrderModule) },
  { path: 'picklist', loadChildren: () => import('./picklist/picklist.module').then(m => m.PicklistModule) },
  { path: 'shiplist', loadChildren: () => import('./shiplist/shiplist.module').then(m => m.ShiplistModule) },
  { path: 'shiplistdetail', loadChildren: () => import('./shiplist-details/shiplist-details.module').then(m => m.ShiplistDetailsModule) },
  { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
  { path: 'import', loadChildren: () => import('./import/import.module').then(m => m.ImportModule) },
  { path: 'barcode', loadChildren: () => import('./barcode/barcode.module').then(m => m.BarcodeModule) },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

//, {useHash: true}
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// { path: '**', redirectTo: '/404', pathMatch: 'full' }
