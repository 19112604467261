import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';

import { AppConfigService } from '../../app-config.service';

@Injectable({
  providedIn: 'root',
})


export class AuthenticationService  {

  constructor(private httpClient: HttpClient,
              public dialog: MatDialog,
              public router: Router,
              private appConfigService: AppConfigService) {}

  private user: User;

  authenticate(username: string, password: string) {

    this.user = null;

    const url = this.appConfigService.getAppURL() + '/api/users/login';

    const authString = 'Basic ' + btoa(username + ':' + password);
    const headers = new HttpHeaders({ Authorization: authString });

    return this.httpClient.get(url, { headers })
    .pipe(
      map(
        userData => {
          this.user = userData as User;

          sessionStorage.setItem('username', this.user.name);
          sessionStorage.setItem('roll', this.user.role);
          sessionStorage.setItem('email', this.user.email);
          sessionStorage.setItem('basicauth', authString);
          return userData;
        }
    ));


  }



  isUserLoggedIn() {
    return !( sessionStorage.getItem('username') === null);
  }

  isUserAdmin() {
    return  sessionStorage.getItem('roll') === 'ADMIN';
  }

  logOut() {

    const dialogRef =  this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'Logout', message: 'Do you want to Logout?', okButtonText: 'Yes', cancelButtonText: 'No'} ,
      width : '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.logUserOut();
      }
    });

  }

  getUser(){
    return this.user;
  }

  public logUserOut() {
    this.user = null;
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('roll');
    sessionStorage.removeItem('basicauth');
    this.router.navigate(['/home']);
  }

}
class User {
  name: string;
  email: string;
  role: string;
}
